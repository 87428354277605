"use strict";
exports.__esModule = true;
exports.PhotoItemForView = void 0;
var moment = require("moment");
var PhotoItemForView = /** @class */ (function () {
    function PhotoItemForView(id, timestamp, imageSource, fNumber, iso, lensMake, lensModel, cameraMake, cameraModel, shutterSpeed) {
        this.id = id;
        this.timestamp = timestamp;
        this.imageSource = imageSource;
        this.fNumber = fNumber;
        this.iso = iso;
        this.lensMake = lensMake;
        this.lensModel = lensModel;
        this.cameraMake = cameraMake;
        this.cameraModel = cameraModel;
        this.shutterSpeed = shutterSpeed;
    }
    PhotoItemForView.fromObject = function (obj) {
        return new PhotoItemForView(obj.id, obj.timestamp, obj.parent.publicURL, obj.fNumber, obj.iso, obj.lensMake, obj.lensModel, obj.cameraMake, obj.cameraModel, obj.shutterSpeed);
    };
    PhotoItemForView.prototype.displayDate = function () {
        var mObj = moment.unix(this.timestamp);
        return mObj.utcOffset(0).format("dddd, MMMM Do YYYY");
    };
    PhotoItemForView.prototype.displayShutterSpeed = function () {
        var rawSpeed = this.shutterSpeed;
        if (rawSpeed == null)
            return "";
        if (rawSpeed < 1) {
            var denominator = Math.round(1 / rawSpeed);
            return "1/" + denominator + "s";
        }
        else {
            return rawSpeed.toString() + "s";
        }
    };
    PhotoItemForView.prototype.displayIso = function () {
        if (this.iso == null)
            return "";
        return "ISO: " + this.iso;
    };
    PhotoItemForView.prototype.toDescription = function () {
        var out = [];
        this.fNumber && out.push("f/" + this.fNumber);
        this.shutterSpeed && out.push(this.displayShutterSpeed());
        this.iso && out.push(this.displayIso());
        if (this.cameraMake || this.cameraModel) {
            out.push("Camera: " + [this.cameraMake || "", this.cameraModel || ""].join(" "));
        }
        if (this.lensMake || this.lensModel) {
            out.push("Lens: " + [this.lensMake || "", this.lensModel || ""].join(" "));
        }
        return out.join(", ");
    };
    return PhotoItemForView;
}());
exports.PhotoItemForView = PhotoItemForView;
