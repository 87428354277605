import * as React from "react"
import { PageProps, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { PhotoItemForView } from "../lib/PhotoItemForView"

import "./index.css"

type DataProps = {
  allPhotoItem: {
    nodes: any[]
  }
}

const IndexPage: React.FC<PageProps<DataProps>> = ({ data, path }) => {
  const photoItemsForView = data.allPhotoItem.nodes.map(e =>
    PhotoItemForView.fromObject(e)
  )
  return (
    <Layout>
      <Seo title="Home" />
      {photoItemsForView.map(p => {
        return (
          <div className="photo-item-container" key={p.id}>
            <h3 className="photo-header">{p.displayDate()}</h3>
            <div className="photo-container">
              <img
                src={p.imageSource}
                className="photo"
                alt={"Photo from " + p.displayDate()}
              />
            </div>
            <p className="photo-description">{p.toDescription()}</p>
          </div>
        )
      })}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    allPhotoItem(sort: { fields: timestamp, order: DESC }) {
      nodes {
        cameraMake
        cameraModel
        fNumber
        id
        iso
        lensMake
        lensModel
        shutterSpeed
        timestamp
        parent {
          ... on File {
            id
            name
            publicURL
          }
        }
      }
    }
  }
`
